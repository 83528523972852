import { getPaginated } from '@/api/implementation/app/wrapper.js';
import { destroy, get, post, put } from '@/api/implementation/app';

function index(page, perPage, search, sortBy, descending, params) {
  return getPaginated('api-token', page, perPage, search, sortBy, descending, params);
}

function show(tokenId) {
  return get(`api-token/${tokenId}`);
}

function create(token) {
  return post('api-token', token);
}

function update(token) {
  return put(`api-token/${token.id}`, token);
}

function remove(tokenId) {
  return destroy(`api-token/${tokenId}`);
}

export {
  index,
  show,
  create,
  update,
  remove,
};
