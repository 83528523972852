<template>
  <v-container fluid>
    <h1 class="text-h1 mb-3">{{ $tc('token.title', 2) }}</h1>

    <v-alert :value="!!token" type="success">
      <v-row class="ml-3">
        {{ $t('token.createdMessage', { token }) }}
        <VSpacer/>
        <v-btn text @click="token=null">{{ $t('actions.close') }}</v-btn>
      </v-row>
    </v-alert>

    <Resource
        :can-update="false"
        :create-request="createHandler"
        :delete-request="deleteHandler"
        :form-component="() => import('../components/forms/TokenForm.vue')"
        :index-request="indexHandler"
        :meta="{name: $tc('token.title', 1), namePlural: $tc('token.title', 2)}"
        :model-type="modelType"
        :show-request="showHandler"
        :table-content="tableContent"
        class="token-resource"
        :can-add="can('api-token.all')"
        :can-delete="can('api-token.all')"
    />
  </v-container>
</template>

<script lang="js">
import Resource from '@/components/Resource.vue';
import eventBus from '@/eventBus.js';
import { create, index, remove, show } from '../api/endpoints/token.js';
import Client from '@/application/models/token.js';
import { mapGetters } from "vuex";

export default {
  name: 'ClientResource',
  components: {
    Resource,
  },
  data: () => ({
    token: null,
  }),
  computed: {
    ...mapGetters("profile", ["can"]),
    indexHandler: () => index,
    showHandler: () => show,
    deleteHandler: () => remove,
    modelType: () => Client,
    tableContent() {
      return [
        {
          text: this.$t('token.fields.clientName'),
          align: 'left',
          sortable: true,
          value: 'clientName',
        },
        {
          text: this.$t('token.fields.token'),
          align: 'left',
          sortable: true,
          value: 'token',
        },
      ];
    },
  },
  created() {
    eventBus.$emit('setBreadcrumbs', [
      {
        exact: true,
        to: { name: 'home' },
        text: this.$t('global.dashboard'),
      },
      {
        exact: true,
        to: { name: 'token.index' },
        text: this.$tc('token.title', 2),
      },
    ]);
  },
  methods: {
    createHandler(...args) {
      return create(...args).then((response) => {
        this.token = response.data.data.token;
      });
    },
  },
};
</script>
