import Model from './model.js';

class Client extends Model {
  id = 0;
  client = null

  mapForRequest() {
    return {
      clientId: this.client?.id
    };
  }
}

export default Client;
